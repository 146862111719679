import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
import MapContainer from "@/components/MapContainer.vue";
export default {
  name: "npAdd",
  components: {
    MapContainer
  },
  mixins: [mixins],
  props: {
    paths: {} // 编辑
  },

  data() {
    return {
      areas: [],
      //区域
      isAdd: false,
      areaProps: {
        value: 'id',
        label: 'name',
        emitPath: false
      },
      type: 'ADD',
      parkType: '',
      address: null,
      edit: false,
      form: {},
      marker: {},
      markers: [],
      mouseTool: null,
      formRules: {
        npName: [{
          required: true,
          message: '请输入站点名称',
          trigger: 'blur'
        }],
        npReason: [{
          required: true,
          message: '请输入禁停原因',
          trigger: 'blur'
        }],
        npArea: [{
          required: true,
          message: '请选择所属区域',
          trigger: 'blur'
        }],
        npAdd: [{
          required: true,
          message: '请填写地区地址',
          trigger: 'blur'
        }]
      }
    };
  },
  beforeMount() {
    this.getAreas();
    if (this.$route.query.id) {
      this.edit = true;
      this.type = 'EDIT';
      const loading = ElLoading.service();
      this.$api.business.np.getById(JSON.stringify({
        id: this.$route.query.id
      })).then(res => {
        if (res.code === 200) {
          this.form = res.data;
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  },
  methods: {
    getAddressData(address) {
      console.log(address);
      this.form.npLongitude = address.lng;
      this.form.npLatitude = address.lat;
      this.form.npAdd = address.address;
      this.form.paths = address.paths;
    },
    begin() {
      if (!this.form.npArea) {
        ElMessage.error("请先选择服务区域");
        return;
      }
      this.parkType = '区域范围';
      this.address = {
        add: this.form.npAdd,
        lng: this.form.npLongitude,
        lat: this.form.npLatitude,
        paths: this.form.paths,
        areaId: this.form.npArea,
        id: this.form.id
      };
      this.isAdd = true;
    },
    Close(s) {
      this.isAdd = s;
    },
    getAreas() {
      this.areas = [];
      this.$api.uac.area.list().then(res => {
        this.areas = res.data;
      });
    },
    // 保存电子围栏
    saveInfo() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          console.log(this.form.paths);
          if (!this.form.paths || this.form.paths.length === 0) {
            ElMessage.error("未绘制禁停区域地图");
          } else {
            const loading = ElLoading.service();
            console.log(this.form);
            const callBack = res => {
              loading.close();
              if (res.code === 200) {
                ElMessage({
                  message: res.message,
                  type: 'success'
                });
                this.$router.back();
                if (this.$route.meta && this.$route.meta.parent) {
                  bus.emit(this.$route.meta.parent, true);
                }
              } else {
                ElMessage.error(res.message);
              }
            };
            if (this.edit) {
              this.$api.business.np.update(this.form).then(callBack);
            } else {
              this.$api.business.np.npAdd(this.form).then(callBack);
            }
          }
        }
      });
    }
  }
};